import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import SpotifyButton from '../components/SpotifyButton';

export default ({ children, ...props }) => {
  const Icon = <SvgIcon viewBox="0 0 167.49 167.49">
    <path d="M83.74,0C37.49,0,0,37.49,0,83.74c0,46.25,37.49,83.74,83.74,83.74c46.25,0,83.74-37.49,83.74-83.74
      C167.49,37.49,129.99,0,83.74,0z M122.15,120.79c-1.5,2.47-4.72,3.24-7.18,1.74c-19.67-12.02-44.42-14.73-73.57-8.07
      c-2.81,0.64-5.61-1.12-6.25-3.92c-0.64-2.81,1.11-5.61,3.93-6.25c31.9-7.29,59.26-4.15,81.34,9.33
      C122.88,115.11,123.65,118.33,122.15,120.79z M132.4,97.98c-1.89,3.07-5.91,4.04-8.98,2.15c-22.5-13.83-56.82-17.84-83.45-9.76
      c-3.45,1.04-7.1-0.9-8.15-4.35c-1.04-3.45,0.91-7.09,4.35-8.14c30.42-9.23,68.23-4.76,94.07,11.13
      C133.32,90.9,134.28,94.92,132.4,97.98z M133.28,74.24c-26.99-16.03-71.52-17.5-97.29-9.68c-4.14,1.26-8.51-1.08-9.77-5.22
      c-1.25-4.14,1.08-8.51,5.22-9.77c29.58-8.98,78.76-7.24,109.83,11.2c3.72,2.21,4.94,7.02,2.74,10.73
      C141.8,75.22,136.99,76.45,133.28,74.24z" />
  </SvgIcon>;
  return (
    <SpotifyButton icon={Icon} {...props}>
      {children || 'Login with Spotify'}
    </SpotifyButton>
  );
};
